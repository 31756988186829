<template>
  <div class="cinema_info">
<!--    <NavBar title="检测报告" class="nav_bottom"></NavBar>-->
    <SearchCinema @set_main_show="getCinemaInfo" v-show="searchShow"></SearchCinema>

    <div v-show="main_show">
      <div class="cinema_info">
        <van-cell-group inset>
          <van-field v-model="cinema_external_name" label="影院对外名" @input="setItem"/>
          <van-field v-model="ic_registration_name" label="工商注册名" @input="setItem"/>
          <van-field v-model="address" label="地点" @input="setItem"/>
          <van-field v-model="seats" label="座位数" type="number" @input="setItem"/>

          <p class="user_label">联系人姓名</p>
          <input type="text" placeholder="请输入..." class="user_name" v-model="user_name" @input="setItem">
          <p class="user_label">联系方式</p>
          <input type="text" placeholder="请输入..." class="user_tel" v-model="user_tel" @input="setItem">
        </van-cell-group>
      </div>

      <div class="inspector_info">
        <van-cell-group inset>
          <p class="user_label">时间</p>
          <div class="time">
            <van-field type="digit" v-model="time_year" @input="year_flag" />
            <span>年</span>
            <van-field type="digit" v-model="time_month" @input="month_flag" />
            <span>月</span>
            <van-field type="digit" v-model="time_day" @input="day_flag" />
            <span>日</span>
          </div>
          <p class="user_label">组长</p>
          <van-field v-model="headman" @input="setItem"/>
          <p class="user_label">检测者</p>
          <van-field v-model="testing" @input="setItem"/>
          <p class="user_label">填写员</p>
          <van-field v-model="fill_in" @input="setItem"/>
        </van-cell-group>
      </div>

      <div class="beizhu">
        <p>备注</p>
        <van-field
            v-model="other"
            rows="1"
            autosize
            type="textarea"
            placeholder="请输入备注"
            @input="setItem"
        />
      </div>

      <div class="submit">
        <van-button class="btn_sub" @click="next" loading-text="跳转中..." :loading="btn_loading">下一步</van-button>
      </div>
    </div>

  </div>
</template>

<script>
import NavBar from "../components/NavBar";
import SearchCinema from "../components/SearchCinema";
import {Loading} from "element-ui";
import {ChooseFu, GetCinema, SetTestingInfo} from "../axios/api"
import {CellGroup, Field, Button, Toast,Dialog} from "vant";

export default {
  name: "TestReport",
  created() {
    if(this.$route.params.mid){ //判断是否从工作安排传过来的问题
      let { mid } = this.$route.params
      let loadingInstance = Loading.service({ fullscreen: true , text: "加载中"});
      this.main_show=true //显示主体内容
      this.cinema_id=mid //保存影厅id 查询影厅信息
      this.searchShow=false //隐藏搜索栏
      this.isHistory(mid) //是否存在这个影院的缓存
      /*获得影院信息*/
      GetCinema({mid:mid,token:localStorage.getItem("token")}).then(respose=>{
        this.cinema_external_name=respose.data.name;
        this.ic_registration_name=respose.data.realname;
        this.address=respose.data.address;
        this.user_name=respose.data.linkman||''
        this.user_tel=respose.data.contact||''
        this.seats = respose.data.seats||''
        /*设置检测小组信息*/
        this.time_year=respose.data.datetime.split("-")[0]
        this.time_month=respose.data.datetime.split("-")[1]
        this.time_day=respose.data.datetime.split("-")[2]
        this.headman=respose.data.leader
        this.testing=respose.data.tester
        this.fill_in=respose.data.writer
        /*关闭loading动画*/
        loadingInstance.close();
      }).catch(error=>{
        console.log(error);
      })
    }
  },
  components:{
    NavBar,SearchCinema,
    [Field.name]:Field,
    [CellGroup.name]:CellGroup,
    [Button.name]:Button,
    [Dialog.Component.name]: Dialog.Component,
  },
  data(){
    return{
      cinema_external_name:'', // 影院对外名
      ic_registration_name:'', // 工商注册名
      address:'', // 地点
      user_name:'', // 联系人名字
      user_tel:'', // 联系人电话
      time_year:'', // 时间 年
      time_month:'', // 时间 月
      time_day:'', // 时间 天
      headman:'', // 组长
      testing:'', // 检测者
      fill_in:'', // 填写员
      main_show:false, // 影院的主要信息是否显示
      cinema_id:'', // 影院id
      btn_loading:false,
      searchShow:true,
      seats:'',
      fuid:'',
      other:''
    }
  },
  methods:{
    /*自定义事件从子组件传入*/
    getCinemaInfo(id,boolear){
      // 获得影院信息 显示内容容器
      if(boolear && id!==-999){
        let loadingInstance = Loading.service({ fullscreen: true , text: "加载中"});
        //显示影院信息
        this.main_show=boolear;
        this.cinema_id=id; //保存当前影院的id
        this.cinema_external_name=this.ic_registration_name=this.address=this.user_name=this.user_tel=''; // 将之前的搜索结果清空
        this.time_year=this.time_month = this.time_day =this.headman =this.testing =this.fill_in = ''; //手动输入的内容也清空
        if(this.$route.query.again==1){
          //复检
          ChooseFu({mid:id,token:localStorage.getItem("token")}).then(respose=>{
            if(respose.state!=1){
              Toast.fail({message:respose.msg})
            }
            this.cinema_external_name=respose.data.name;
            this.ic_registration_name=respose.data.realname;
            this.address=respose.data.address;
            this.user_name=respose.data.linkman||'';
            this.user_tel=respose.data.contact||'';
            this.seats = respose.data.seats||''
            //将原先的检测信息显示
            this.time_year=respose.data.year||'';
            this.time_month = respose.data.month||''
            this.time_day =respose.data.date||''
            this.headman =respose.data.leader||''
            this.testing =respose.data.tester||''
            this.fill_in =respose.data.writer||''
            this.fuid = respose.data.id  //复检时需要
            this.other = respose.data.comment
            loadingInstance.close();
          }).catch(error=>{
            console.log(error);
          })
        }else{
          //初检
          this.isHistory(id)//判断是否存在未提交的数据
          GetCinema({mid:id,token:localStorage.getItem("token")}).then(respose=>{
            this.cinema_external_name=respose.data.name;
            this.ic_registration_name=respose.data.realname;
            this.address=respose.data.address;
            this.user_name=respose.data.linkman||'';
            this.user_tel=respose.data.contact||''
            this.seats = respose.data.seats||''

            this.time_year=respose.data.year||'';
            this.time_month = respose.data.month||''
            this.time_day =respose.data.date||''
            this.headman =respose.data.leader||''
            this.testing =respose.data.tester||''
            this.fill_in =respose.data.writer||''
            this.other = respose.data.comment
            loadingInstance.close();
          }).catch(error=>{
            console.log(error);
          })
        }
      }else{
        //点击input 隐藏影院信息
        if(boolear){
          if(this.cinema_external_name){
            //如果原来存在搜索数据
            this.main_show=true;
          }else{
            this.main_show=false;
          }
        }else{
          this.main_show=boolear;
        }
      }
    },

    /*下一步按钮事件*/
    next(){
      if(this.time_year && this.time_month && this.time_day && this.headman && this.testing && this.fill_in && this.user_name && this.user_tel){
        /*用户全部填完*/
        this.btn_loading=true;
        SetTestingInfo({
          mid:this.cinema_id,
          token:localStorage.getItem("token"),
          linkman:this.user_name,
          contact:this.user_tel,
          leader:this.headman,
          tester:this.testing,
          writer:this.fill_in,
          datetime:this.time_year+'-'+this.time_month+'-'+this.time_day,
          seats:this.seats,
          did:this.fuid, //存在就是复检
          comment:this.other, //整体备注
        }).then(respose=>{
          console.log(respose)
          if(respose.state==="1"){
            localStorage.removeItem('testReport') //将本次保存的影院删除
            /*保存id*/
            localStorage.setItem("did",respose.data);
            /*成功后跳转到选择影厅*/
            this.$router.push({name:'SelectionHall',params:{id:this.cinema_id}})
          }else{
            Toast.fail({message:respose.msg})
          }
          this.btn_loading=false;
        }).catch(error=>{
          console.log(error)
        })
      }else{
        /*有内容没填写*/
        /*Message.warning("有内容没有填写哦")*/
        Toast.fail({message:"有内容没有填写哦"})
      }
    },

    //检测时间是否正确begin
    month_flag(val){
      this.setItem()
      if(val<0 || val>12){
        Toast.fail({message:"请输入正确的时间"})
        this.time_month=this.time_month.slice(0,-1)
      }
    },
    year_flag(val){
      this.setItem()
      if(val<0 || val>9999){
        Toast.fail({message:"请输入正确的时间"})
        this.time_year=this.time_year.slice(0,-1)
      }
    },
    day_flag(val){
      this.setItem()
      if(val<0 || val>31){
        Toast.fail({message:"请输入正确的时间"})
        this.time_day=this.time_day.slice(0,-1)
      }
    },
    //检测时间是否正确end

    /*将数据缓存*/
    setItem(){
      let arr = {
        cinema_external_name:this.cinema_external_name,
        ic_registration_name:this.ic_registration_name,
        address:this.address,
        user_name:this.user_name,
        user_tel:this.user_tel,
        time_year:this.time_year,
        time_month:this.time_month,
        time_day:this.time_day,
        headman:this.headman,
        testing:this.testing,
        fill_in:this.fill_in,
        cinema_id:this.cinema_id,
        seats:this.seats,
        other:this.other
      }
      localStorage.setItem('testReport',JSON.stringify(arr))
    },
    /*检测是否存在需要恢复的数据*/
    isHistory(id){
      if(localStorage.getItem('testReport')){
        let arr = JSON.parse(localStorage.getItem('testReport'))
        if(arr.cinema_id == id){ //当选择的影院id和存在缓存里的影院id一致时
          Dialog.confirm({
            title: '提示',
            message: '检测到存在未提交的数据',
            getContainer : ".cinema_info",
            confirmButtonText:'恢复',
            cancelButtonText:'清除'
          }).then(()=>{
            this.main_show=true;
            this.cinema_external_name=arr.cinema_external_name;
            this.ic_registration_name=arr.ic_registration_name;
            this.address=arr.address;
            this.user_name=arr.user_name;
            this.user_tel=arr.user_tel;
            this.seats=arr.seats
            /*设置检测小组信息*/
            this.time_year=arr.time_year
            this.time_month=arr.time_month
            this.time_day=arr.time_day
            this.headman=arr.headman
            this.testing=arr.testing
            this.fill_in=arr.fill_in
            this.other=arr.other
          }).catch(()=>{
            localStorage.removeItem('testReport')
          });
        }
      }
    }
  }
}
</script>

<style scoped>
/*.nav_bottom{*/
/*  margin-bottom: 26px*/
/*}*/
.cinema_info{
  padding-top: 26px;
}
.cinema_info>>>.van-cell-group--inset {
  margin: 0 auto 77px;
  padding: 0 34px 64px 36px;
  width: calc(620px);
  border-radius: 20px;
  background: #FFFFFF;
}
.cinema_info>>>.van-field:nth-of-type(1) {
  padding: 58px 0 20px;
}
.cinema_info>>>.van-field:nth-of-type(4) {
  margin-bottom: 64px;
}
.cinema_info>>>.van-field {
  padding: 66px 0 20px;
  font-size: 30px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  border-bottom: 2px solid #F2F2F2;
}
.cinema_info>>>.van-field__label {
  color: #353535 !important;
}
.cinema_info>>>.van-field__control {
  text-align: right;
  color: #343434;
}
.cinema_info>>>.van-cell::after {
  border-bottom: 0px;
}
.cinema_info .user_name,
.cinema_info .user_tel {
  width: calc(560px);
  height: calc(39px);
  background: rgba(227, 229, 238, 0.38);
  border-radius: 9px;
  border: 0px;
  font-size: 28px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  padding: 20px 31px;
}
.cinema_info .user_label {
/*  width: 152px;*/
  height: 42px;
  font-size: 30px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #000000;
  line-height: 35px;
  margin: 13px 0;
}
.cinema_info .user_label:nth-last-of-type(1) {
  margin: 36px 0 13px 0;
}
.inspector_info>>>.van-cell-group--inset {
  margin: 0 auto 50px;
  padding: 26px 35px 63px 35px;
  width: calc(620px);
  border-radius: 20px;
  background: #FFFFFF;
}
.inspector_info>>>.van-cell-group--inset .user_label {
  margin: 25px 0 11px;
  font-size: 30px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #353535;
}
.inspector_info>>>.van-cell-group--inset .user_label::after {
  content: "*";
  color: #F52020;
  position: relative;
  top: -7px;
  left: 1px;
  width: 14px;
  height: 13px;
}
.inspector_info>>>.van-cell-group--inset .time {
  display: flex;
  align-items: center;
}
.inspector_info>>>.van-cell-group--inset .time span {
  font-size: 30px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #353535;
  margin-right: 21px;
}
.inspector_info>>>.van-cell-group--inset .time span:nth-of-type(1) {
  margin-right: 31px;
}
.inspector_info>>>.van-cell-group--inset .time .van-field:nth-of-type(1) {
  width: 196px;
  height: 79px;
  background: #F4F5F8;
  border-radius: 9px;
  padding: 0 31px;
}
.inspector_info>>>.van-cell-group--inset .time .van-field:nth-of-type(1) .van-field__body .van-field__control {
  text-align: left;
  height: 79px;
}
.inspector_info>>>.van-cell-group--inset .time .van-field {
  width: 127px;
  height: 79px;
  background: #F4F5F8;
  border-radius: 9px;
  padding: 0 31px;
  margin: 0 10px 0 0;
}
.inspector_info>>>.van-cell-group--inset .time .van-field .van-field__body .van-field__control {
  text-align: left;
  height: 79px;
}
.inspector_info>>>.van-cell-group--inset .van-field {
  padding: 0 31px;
  margin: 0 10px 0 0;
  width: 620px;
  height: 79px;
  background: #F4F5F8;
  border-radius: 9px;
}
.inspector_info>>>.van-cell-group--inset .van-field .van-field__body .van-field__control {
  text-align: left;
  height: 79px;
}
.submit {
  width: 750px;
  height: 166px;
  background: #FFFFFF;
  box-shadow: 0 4px 13px 5px rgba(112, 112, 112, 0.06);
  text-align: center;
}
.submit .btn_sub {
  width: 691px;
  height: 89px;
  background: linear-gradient(270deg, #3054F8 0%, #4768FF 100%);
  box-shadow: 0px 0px 18px 3px rgba(66, 95, 220, 0.35);
  border-radius: 41px;
  opacity: 0.87;
  margin-top: 18px;
  border: 0;
  font-size: 36px;
  font-family: PingFang SC-Heavy, PingFang SC;
  font-weight: 800;
  color: #FFFFFF;
}
>>>.van-dialog{
  width: 640px;
}
>>>.van-dialog__header{
  padding-top: 26px;
  padding-bottom: 20px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  font-size: 32px;
}
>>>.van-dialog__message{
  font-size: 28px;
  padding-top: 20px;
}
.cinema_info>>>.van-dialog .van-button__text {
  color: #5270FB;
  font-size: 36px;
  font-family: PingFang SC-Heavy, PingFang SC;
  font-weight: 800;
}
.cinema_info>>>.van-dialog__footer {
  padding: 0;
  margin: 0 auto;
  width: 552px;
  height: 100px;
  border-top: 1px solid #F2F2F2;
}
.cinema_info>>>.van-dialog__footer button{
  height: 100px;
  border: none!important;
  /*font-size: 16px;*/
}

.beizhu{
  width: 620px;
  margin: 0 auto 111px;
  padding: 26px 35px 53px 35px;
  border-radius: 20px;
  background: #FFFFFF;
}
.beizhu p{
  height: 42px;
  font-size: 30px;
  font-family: PingFang SC-Bold, PingFang SC;
  color: rgba(0, 0, 0, 0.69);
  margin: 0 0 20px;
  font-weight: 500;
  color: #353535;
}
.beizhu /deep/ .van-cell{
  width: 100%;
  font-size: 32px;
  padding: 10px 16px!important;
  background: #F4F5F8;
  margin: 0 10px 0 0;
  border-radius: 9px;
  border: none;
}
.beizhu /deep/ .van-field__control{
  text-align: left;
  line-height: 45px;
  font-size: 30px;
  font-weight: bold;
}
.beizhu /deep/ .van-cell::-webkit-scrollbar ,.beizhu /deep/ .van-cell textarea::-webkit-scrollbar{
  display:none
}
</style>
