<template>
  <div class="test_report_search">
    <main>
      <div class="search">
        <input type="text" placeholder="请输入关键词..." v-model="cinemaName" @focus="input_focus" @blur="input_blue">
        <div class="icon" @click="search"></div>
      </div>
      <div class="search_result" >
        <van-loading v-show="loadingShow" color="#1989fa" />
        <van-empty v-show="emptyShow" image="search" description="未查到相关影院" />
        <div class="item" v-for="v in cinemaArray" :key="v.userid" @click="toCinema(v.userid)" v-show="showResult">
          <span>{{v.name}}</span>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import {Empty, Loading, Toast} from "vant";
import {SearchCinema} from "../axios/api"

export default {
  name: "SearchCinema",
  components:{
    [Empty.name]:Empty,
    [Loading.name]:Loading
  },
  data(){
    return{
      showResult:false,
      cinemaName:'',
      cinemaArray:'',
      emptyShow:false,
      loadingShow:false,
      searchFlag:false
    }
  },
  methods:{
    search(){
      if(this.cinemaName){
        this.searchFlag=true //修改按钮点击标志
        this.emptyShow=false //空内容隐藏
        this.loadingShow=true; //展示loading动画
        this.$emit("set_main_show",-999,false); //隐藏父组件主内容
        SearchCinema({keyword:this.cinemaName,token:localStorage.getItem("token")}).then(respose=>{
          if(respose.state==="1"){
            this.loadingShow=false; //关闭loading动画 显示结果
            //显示搜索结果容器
            this.showResult=true
            //将结果赋值给数组，页面渲染
            this.cinemaArray=respose.data;
          }else if(respose.state==="0"){
            this.loadingShow=false; //关闭loading动画 显示空状态
            this.emptyShow=true
          }
        }).catch(error=>{
          console.log(error);
        })
      }else{
        Toast.fail("请先输入关键词")
      }
      this.searchFlag=false
    },
    /*用户选中影院，将影院id传给父组件*/
    toCinema(id){
      this.$emit("set_main_show",id,true)
      this.showResult=false
    },
    /*input获得焦点事件 用户想要搜索影院，告诉父组件隐藏当前影院信息*/
    input_focus(){
      this.$emit("set_main_show",-999,false);
      this.emptyShow=false //隐藏结果
      this.showResult=false
    },
    input_blue(){
      // if(!this.cinemaName){}
      //判断离开是否点击了搜索，还是其他
      if(this.searchFlag){
        //点击了搜索
      }else{
        //点击了其他 显示面板
        this.$emit("set_main_show",-999,true)
      }

    }
  }
}
</script>

<style scoped>
.test_report_search {
  padding-bottom: 43px;
}
main {
  width: 690px;
  min-height: 87px;
  border-radius: 28px 28px 19px 19px;
  background: #FFFFFF;
  margin: 0 auto;
  overflow: hidden;
}
main .search {
  width: 690px;
  height: 87px;
  box-shadow: 0 7px 31px 1px rgba(189, 224, 243, 0.51);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
main .search input {
  float: left;
  width: 572px;
  height: 39px;
  font-size: 28px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  line-height: 33px;
  border: 0;
  margin: 24px 0 24px 22px;
}
main .search .icon {
  float: left;
  width: 92px;
  height: 55px;
  background-image: url("../assets/Group 198.png");
  margin: 16px 0;
  background-repeat: no-repeat;
  background-size: 72px 55px;
}
main .item {
  margin: 0 20px 0 22px;
  width: 600px;
  min-height: 42px;
  border-bottom: 2px solid #F2F2F2;
  padding: 30px 33px 22px 15px;
}
main .item span {
  font-size: 30px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #353535;
}
main .item:nth-last-of-type(1) {
  border-bottom: 0;
  margin-bottom: 12px;
}
/*搜索空状态样式*/
>>>.van-empty{
  width: 690px;
  height: 275px;
}
>>>.van-empty__image{
  width: 160px;
  height: 160px;
}
/*搜索动画*/
>>>.van-loading{
  width: 690px;
  height: 275px;
  text-align: center;
  line-height: 275px;
}
>>>.van-loading__spinner{
  width: 30px;
  height: 30px;
}
</style>